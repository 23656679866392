import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import { NO_ADD_ERROR_MESSAGE_KEYS } from 'pages/Booking/constants';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import convertTargetsText from 'utils/convertTargetsText';
import filters from 'utils/filters';

import convertRepeatWeekdayNum from '../convertRepeatWeekdayNum';

export const getCurrentSelectedUserTicketIds = (selectedUserTickets: UserTicketLectureResponse[]) => {
  return selectedUserTickets.map(({ id }) => id);
};

/** 프라이빗 수업에서 회원 선택 시 수업명 없으면 회원명으로 수업명 자동 세팅  */
export const getPrivateTitle = (selectedUserTickets: UserTicketLectureResponse[]) => {
  const member = selectedUserTickets.map(({ member }) => member);
  return member.length === 1 ? `${member[0].name} 님` : convertTargetsText(member, '', false);
};

/**
 * 주/월간 초과 검증 요청 보낼 수강권 아이디 배열
 * - 수업 생성: 선택한 아이디 모두
 * - 이후 모든 수정/복사: 생성 때 선택된 아이디 제외한 선택한 아이디
 */
const getIds = ({
  userTicketIds,
  originUserTickets,
}: {
  userTicketIds: Array<number>;
  originUserTickets: BookingPrivateFormType['originUserTickets'];
}) => {
  const originUserTicketIds = originUserTickets.map(({ id }) => id);
  const resultArray = userTicketIds.filter(id => !originUserTicketIds.includes(id));

  // 수업 생성
  if (resultArray.length === originUserTicketIds.length) {
    return userTicketIds;
  }

  // 이후 모든 수정/복사
  return resultArray.length ? resultArray : originUserTicketIds;
};

/** 주/월간 초과 검증 요청 파라미터 */
export const formatBookingCountParams = (formValues: BookingPrivateFormType) => {
  const { originUserTickets, selectedUserTickets, startDate, endDate, repeat, repeatCustomWeekday } = formValues;
  const userTicketIds = getCurrentSelectedUserTicketIds(selectedUserTickets);
  return {
    start_date: filters.dateDashTimeSecondZero(startDate),
    end_date: filters.dateDashTimeSecondZero(endDate),
    user_ticket_ids: getIds({ userTicketIds, originUserTickets }),
    member_ids: selectedUserTickets.map(({ member }) => member.id),
    weekday: convertRepeatWeekdayNum(repeat, repeatCustomWeekday),
  };
};

export const userTicketErrorMessage = (errorData: any) => {
  const addMessage = `${NO_ADD_ERROR_MESSAGE_KEYS.includes(errorData?.code) ? '' : '\n수강권 정보를 확인해 주세요.'}`;
  return `${errorData?.message}${addMessage}`;
};
