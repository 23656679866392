import 'dayjs/locale/ko';

import styled from '@emotion/styled';
import {
  Eventcalendar,
  localeKo,
  MbscCalendarEventData,
  MbscCellClickEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventList,
  MbscPageChangeEvent,
  MbscPageLoadedEvent,
  MbscSelectedDateChangeEvent,
  setOptions,
} from '@mobiscroll/react';
import { useDrag } from '@use-gesture/react';
import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useGetAllScheduleAllDayEtcSchedule from 'hooks/service/queries/useGetAllScheduleAllDayEtcSchedule';
import useGetAllScheduleAllDayEtcScheduleTime from 'hooks/service/queries/useGetAllScheduleAllDayEtcScheduleTime';
import useGetAllScheduleCounsel from 'hooks/service/queries/useGetAllScheduleCounsel';
import useGetAllScheduleEtcSchedule from 'hooks/service/queries/useGetAllScheduleEtcSchedule';
import useGetAllScheduleLecture from 'hooks/service/queries/useGetAllScheduleLecture';
import useGetHolidayTarget, { HolidayTargetResponse } from 'hooks/service/queries/useGetHolidayTarget';
import useGetMySchedule from 'hooks/service/queries/useGetMySchedule';
import useScrollContentAtTop from 'hooks/useScrollContentAtTop';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useStatusBarColor from 'hooks/useStatusBarColor';
import { isEqual, uniqBy } from 'lodash';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { calendarSettingsAtom } from 'recoil/calendarSettings';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import {
  calendarViewTypeAtom,
  currentDateAtom,
  headerDatePickerOpenAtom,
  listCalendarTypeAtom,
  selectedFiltersAtom,
} from 'recoil/schedule';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import CenterLineLoading from 'sharedComponents/CenterLineLoading';
import filters from 'utils/filters';
import { Color } from 'utils/getColor';

import calendarStyles from '../calendarStyles';
import { EVENT_CARD_TYPE } from '../constants';
import { CalendarViewType, CurrentPeriodTyps, LectureParamsType, MarkedType } from '../types';
import { allDayEtcScheduleDataset, counselDataset, etcScheduleDataset, lectureDataset } from '../utils/calendarDataset';
import getEventUrl from '../utils/getEventUrl';
import getParams from '../utils/getParams';
import ListTypeAgendaList from './agenda/ListTypeAgendaList';
import NoEvents from './agenda/NoEvents';
import CustomCalendarHeader from './CustomCalendarHeader';
import CalendarAgendaDrawer from './drawer/CalendarAgendaDrawer';
import CreateScheduleDrawer from './drawer/CreateScheduleDrawer';
import MonthEventCard from './eventCard/MonthEventCard';
import ScheduleEventCard from './eventCard/ScheduleEventCard';

dayjs.locale('ko');
dayjs.extend(customParseFormat);

setOptions({ locale: localeKo });

const ScheduleCalendar = () => {
  useScrollRestoration();

  /**
   * mobiscroll 내부 클래스를 매칭해야 하는데, 직접 삽입할 수 없어 아래 useEffect로 삽입
   * ref는 자동으로 매칭되는 readonly인데, 직접 넣으려고 해서 unknown 추가
   */
  const stickyRef = useRef<HTMLDivElement | unknown>(null);
  useEffect(() => {
    const target = document.querySelector('.mbsc-calendar-wrapper');
    if (target) {
      stickyRef.current = target;
    }
  }, []);
  const { isAtTop } = useScrollContentAtTop({ stickyRef: stickyRef as RefObject<HTMLDivElement> });
  useStatusBarColor(isAtTop ? theme.color.white : 'linear');

  const navigate = useNavigate();

  const calendarSwipeRef = useRef(null);

  const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
  const [isAgendaDrawerOpen, setIsAgendaDrawerOpen] = useState(false);

  const studioId = useRecoilValue(studioIdAtom);
  const staffId = useRecoilValue(staffIdAtom);
  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const calendarSettings = useRecoilValue(calendarSettingsAtom);
  const { schedules, staffs } = useRecoilValue(selectedFiltersAtom);
  /** 현재 선택된 날짜 */
  const [currentDate, setCurrentDate] = useRecoilState(currentDateAtom);
  const [isDatePickerOpen, setIsDatePickerOpen] = useRecoilState(headerDatePickerOpenAtom);
  const [marked, setMarked] = useState<MarkedType>([]);
  const listCalendarType = useRecoilValue(listCalendarTypeAtom);

  useEffect(() => {
    if (calendarViewType !== 'month') {
      document.getElementById('scrollableTarget')?.scrollTo({ top: 0 });
    }
  }, [calendarViewType]);

  const isDay = useMemo(() => calendarViewType === 'day', [calendarViewType]);
  const isWeek = useMemo(() => calendarViewType === 'week', [calendarViewType]);
  const isMonth = useMemo(() => calendarViewType === 'month', [calendarViewType]);
  const isListType = useMemo(() => calendarViewType === 'list', [calendarViewType]);

  /** 실제 요청 기간 */
  const [currentPeriod, setCurrentPeriod] = useState<CurrentPeriodTyps>({
    startDate: isListType
      ? listCalendarType === 'week'
        ? dayjs(currentDate).startOf('week').toDate()
        : dayjs(currentDate).startOf('month').toDate()
      : new Date(),
    endDate: isListType
      ? listCalendarType === 'week'
        ? dayjs(currentDate).endOf('week').toDate()
        : dayjs(currentDate).endOf('month').toDate()
      : new Date(),
  });
  const commonParams = useMemo(() => {
    return {
      start_date: currentPeriod && filters.dateDash(currentPeriod.startDate),
      end_date: currentPeriod && filters.dateDash(currentPeriod.endDate),
      staff_ids: staffs.includes('all') ? '' : (staffs.join(';') as string),
      studioId,
    };
  }, [currentPeriod, staffs, studioId]);

  /** 일정 이벤트 api paramsSet */
  const lectureParams = useMemo(() => getParams('lecture', schedules, !!currentPeriod), [schedules, currentPeriod]);
  const counselParams = useMemo(() => getParams('counsel', schedules, !!currentPeriod), [schedules, currentPeriod]);
  const etcScheduleParams = useMemo(
    () => getParams('etcSchedule', schedules, !!currentPeriod, calendarSettings.etcScheduleHide),
    [schedules, currentPeriod, calendarSettings.etcScheduleHide],
  );

  const {
    data: lectureEvents = [],
    isFetching: isFetching1,
    refetch: lectureRefetch,
  } = useGetAllScheduleLecture({
    ...commonParams,
    ...(lectureParams as LectureParamsType),
  });
  const {
    data: counselEvents = [],
    isFetching: isFetching2,
    refetch: counselRefetch,
  } = useGetAllScheduleCounsel({ ...commonParams, ...counselParams });
  const {
    data: etcScheduleEvents = [],
    isFetching: isFetching3,
    refetch: etcRefetch,
  } = useGetAllScheduleEtcSchedule({
    ...commonParams,
    ...etcScheduleParams,
  });
  const {
    data: allDayEtcScheduleEvents = [],
    isFetching: isFetching4,
    refetch: allDayEtcRefetch,
  } = useGetAllScheduleAllDayEtcSchedule({
    ...commonParams,
    ...etcScheduleParams,
  });
  const {
    data: allDayEtcScheduleTimeEvents = [],
    isFetching: isFetching5,
    refetch: allDayEtcTimeRefetch,
  } = useGetAllScheduleAllDayEtcScheduleTime({
    ...commonParams,
    ...etcScheduleParams,
  });

  const { data: markedResponse = [] } = useGetMySchedule({
    start_date: filters.dateDash(currentPeriod?.startDate),
    end_date: filters.dateDash(currentPeriod?.endDate),
  });

  const isLoading = isFetching1 || isFetching2 || isFetching3 || isFetching4 || isFetching5;

  const startDate = isListType
    ? listCalendarType === 'week'
      ? dayjs(currentDate).startOf('week').format('YYYY-MM-DD')
      : dayjs(currentDate).startOf('month').format('YYYY-MM-DD')
    : filters.dateDash(currentPeriod?.startDate);
  const endDate = isListType
    ? listCalendarType === 'week'
      ? dayjs(currentDate).endOf('week').format('YYYY-MM-DD')
      : dayjs(currentDate).endOf('month').format('YYYY-MM-DD')
    : filters.dateDash(currentPeriod?.endDate);

  const { data: holidayTargets = [] } = useGetHolidayTarget({
    start_date: currentPeriod && startDate,
    end_date: currentPeriod && endDate,
    enabled: !!currentPeriod,
  });

  useEffect(() => {
    if (isWeek) {
      const targets = document.getElementsByClassName('mbsc-schedule-header-dayname');
      for (const target of targets) {
        if (target.textContent === '토') {
          (target as HTMLElement).style.color = '#0D88D9';
        }

        if (target.textContent === '일') {
          (target as HTMLElement).style.color = '#F4675C';
        }
      }
    }
  }, [isWeek, currentPeriod, currentDate]);

  useEffect(() => {
    /** 주간, 월간 캘린더 휴일 텍스트 색 변경, mobiscroll 내부 콘텐츠라 css에서는 불가능해서 javascript로 진행 */
    if (isDay) return;
    const currentTarget = isWeek ? 'mbsc-schedule-header-day' : 'mbsc-calendar-day-text';
    const targets = document.getElementsByClassName(currentTarget);
    if (!targets?.length) return;
    for (const target of targets) {
      const element = target as HTMLElement;

      const changeColor = (color: Color, important?: 'important') => {
        element.style.setProperty('color', theme.color[color], important);
      };

      if (isWeek) {
        const sliceTargets = holidayTargets.map(holiday => {
          const sliceDate = holiday.slice(-2);
          return sliceDate[0] === '0' ? sliceDate.slice(-1) : sliceDate;
        });
        if (sliceTargets.includes(target.textContent || '')) {
          /** 휴일 텍스트 색 변경 */
          changeColor('secondary3', 'important');
        } else {
          changeColor('gray2');
        }
      } else if (isMonth || isListType) {
        /** 월간의 경우, 일자만 있는 mobiscroll에서 aria-label을 가지고와 포맷 형태의 날짜로 변경 */
        const parsedDate = dayjs(target.getAttribute('aria-label'), 'dddd, M월 D, YYYY', 'ko').format('YYYY-MM-DD');
        if (holidayTargets.includes(parsedDate || '')) {
          /** 휴일 텍스트 색 변경 */
          changeColor('secondary3', 'important');
        } else {
          changeColor('gray2');
        }
      }
    }
  }, [isDay, isWeek, isMonth, isListType, holidayTargets, listCalendarType]);

  /** 캘린더 이벤트 삽입을 위한 데이터 가공(필수) */
  const lectureCalendarData = useMemo(() => lectureDataset(lectureEvents), [lectureEvents]);
  const counselCalendarData = useMemo(() => counselDataset(counselEvents), [counselEvents]);
  const etcScheduleCalendarData = useMemo(() => etcScheduleDataset(etcScheduleEvents), [etcScheduleEvents]);
  /** 종일 기타 일정 중, 반복일정 설정되지 않은 코스 일정 */
  const allDayEtcScheduleCalendarData = useMemo(
    () => allDayEtcScheduleDataset(allDayEtcScheduleEvents),
    [allDayEtcScheduleEvents],
  );
  /** 종일 기타 일정 중, 반복일정 설정된 개별 일정 */
  const allDayEtcScheduleTimeCalendarData = useMemo(
    () => allDayEtcScheduleDataset(allDayEtcScheduleTimeEvents),
    [allDayEtcScheduleTimeEvents],
  );

  const allCalendarData = useMemo(() => {
    return [
      ...lectureCalendarData,
      ...counselCalendarData,
      ...etcScheduleCalendarData,
      ...allDayEtcScheduleCalendarData,
      ...allDayEtcScheduleTimeCalendarData,
    ];
  }, [
    lectureCalendarData,
    counselCalendarData,
    etcScheduleCalendarData,
    allDayEtcScheduleCalendarData,
    allDayEtcScheduleTimeCalendarData,
  ]);

  useEffect(() => {
    if (!currentPeriod) return;

    /** 최초 marked 등록(전체 월 단위 요청일 때) */
    if (isListType && dayjs(currentPeriod.startDate).diff(currentPeriod.endDate)) {
      const newMarked = markedResponse.map(mark => {
        return {
          date: mark,
        };
      });
      if (!isEqual(newMarked, marked)) {
        setMarked(newMarked);
      }
    } else {
      /** marked 업데이트(날짜별 하루 요청) */
      const date = filters.dateDash(currentDate);
      const newMarked = markedResponse.includes(filters.dateDash(currentDate))
        ? uniqBy([...marked, { date }], 'date')
        : marked.filter(m => m.date !== date);

      if (!isEqual(newMarked, marked)) {
        setMarked(newMarked);
      }
    }
  }, [isListType, currentPeriod, allCalendarData, currentDate, marked, staffId, markedResponse]);

  const agendaData = useMemo(() => {
    if (isMonth) return allCalendarData;
    return [...etcScheduleCalendarData, ...allDayEtcScheduleCalendarData, ...allDayEtcScheduleTimeCalendarData].filter(
      data => data.allDay,
    );
  }, [isMonth, allCalendarData, etcScheduleCalendarData, allDayEtcScheduleCalendarData, allDayEtcScheduleTimeCalendarData]);

  const getMoveDate = (day: number): Date => {
    const date = new Date(currentDate);
    return new Date(date.setDate(date.getDate() + day));
  };

  /** 캘린더 Swipe 이동을 위한 라이브러리 적용 함수 */
  const gestureBind = useDrag(({ last: isLeave, movement: [currentX] }) => {
    /** 월간 형태는 onSelectedDateChange 이벤트에서 따로 스와이프 */
    if (isMonth || isListType || !isLeave) return;

    if (currentX < -70 || currentX > 70) {
      const moveNumber = calendarViewType === 'day' ? 1 : 7;
      const currentMove = currentX < -70 ? moveNumber : currentX > 70 ? -moveNumber : 0;

      const nextDate = getMoveDate(currentMove);
      setCurrentDate(nextDate);
    }
  });

  const closeHeaderPicker = () => setIsDatePickerOpen(false);

  const clickEvent = ({ event }: MbscEventClickEvent) => {
    if (isDatePickerOpen) {
      closeHeaderPicker();
      return;
    }

    if (isMonth) {
      setIsAgendaDrawerOpen(true);
    } else {
      switch (event.type) {
        case EVENT_CARD_TYPE.counsel:
          navigate(`/counsel/detail/${event.id}`);
          break;
        case EVENT_CARD_TYPE.etcSchedule:
          navigate(`/schedule/etc/detail/${event.id}`);
          break;
        case EVENT_CARD_TYPE.allDayEtcSchedule: {
          const baseUrl = getEventUrl(event.isBulkAllDay);
          navigate(`${baseUrl}/${event.id}`);
          break;
        }
        default:
          navigate(`/booking/detail/${event.id}`);
      }
    }
  };

  const clickEmptyEvent = (e: { domEvent: MbscCellClickEvent['domEvent']; date: Date }) => {
    if (isDatePickerOpen) {
      closeHeaderPicker();
    } else {
      if (isListType) return;
      if (isMonth) {
        if (e.domEvent.target.classList[0] === 'mbsc-calendar-cell-inner') {
          setCurrentDate(e.date);
          setIsCreateDrawerOpen(true);
        } else {
          setIsAgendaDrawerOpen(true);
        }
      } else {
        setCurrentDate(e.date);
        setIsCreateDrawerOpen(true);
      }
    }
  };

  const onMonthPageSlide = ({ date }: MbscSelectedDateChangeEvent) => {
    const dateWithType = date as Date;
    setCurrentDate(dateWithType);
    if (isListType) {
      setCurrentPeriod({ startDate: dateWithType, endDate: dateWithType });
    }
  };

  const changePeriod = (firstDay: Date, lastDay: Date) => {
    const endDate = dayjs(lastDay).subtract(1, 'days').toDate();
    const period = { startDate: firstDay, endDate };

    const beforeCurrentPeriod = {
      startDate: filters.dateDash(currentPeriod?.startDate),
      endDate: filters.dateDash(currentPeriod?.endDate),
    };

    const beforePeriod = {
      startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(period.endDate).format('YYYY-MM-DD'),
    };

    const isPeriodEqual = isEqual(beforeCurrentPeriod, beforePeriod);
    if (isPeriodEqual) return;

    requestAnimationFrame(() => setCurrentPeriod(period));
  };

  const onPageChange = ({ firstDay, lastDay, month }: MbscPageChangeEvent) => {
    changePeriod(firstDay, lastDay);

    const isBetweenDate = dayjs().isBetween(firstDay, lastDay, 'day', '[]');
    const isToday = filters.dateDash(month) === filters.dateDash(new Date());

    /** 목록형 주 단위 형태에서는 패스*/
    if (isListType && listCalendarType === 'week' && !dayjs().isSame(currentDate, 'week')) {
      return;
    }

    if ((isToday && isWeek && isBetweenDate) || ((isListType || isMonth) && dayjs().isSame(currentDate, 'month'))) {
      requestAnimationFrame(() => {
        setCurrentDate(new Date());
      });
    }
  };

  const onPageLoaded = ({ firstDay, lastDay }: MbscPageLoadedEvent) => {
    if (isListType) return;
    changePeriod(firstDay, lastDay);
  };

  const customCalendarHeader = () => {
    return (
      <ApiBoundary>
        <CustomCalendarHeader
          key={String(studioId)}
          holidayTargets={holidayTargets}
          currentPeriod={currentPeriod}
          setCurrentPeriod={setCurrentPeriod}
        />
      </ApiBoundary>
    );
  };

  /**
   * data: 캘린더 data 속성에 있는 각 이벤트의 값
   * data.original: Eventcalendar의 data 속성에 넣는 값
   */
  const customRenderScheduleEvent = ({ original, ...data }: MbscCalendarEventData) => {
    if (!original) return;
    return <ScheduleEventCard eventData={data} original={original} />;
  };

  /** 월간은 라이브러리 view 타입이 calendar라 별도 커스텀 */
  const customRenderCalendarEvent = ({ original }: MbscCalendarEventData) => {
    if (!original) return;
    return <MonthEventCard original={original} />;
  };

  /** 목록형 아젠다 별도 커스텀 */
  const customRenderListEvent = (events: MbscEventList[]) => {
    if (!events.length) {
      return <NoEvents />;
    }

    const filteredEtc = events[0].events.filter(({ original }) => original?.type === 'AE');
    const remainEvents = events[0].events.filter(({ original }) => original?.type !== 'AE');

    const sortEtc = filteredEtc.sort((a, b) => {
      return dayjs(a.original?.createdAt).valueOf() - dayjs(b.original?.createdAt).valueOf();
    });
    const combineEvents = [...sortEtc, ...remainEvents];

    return (
      <ListTypeAgendaList
        events={combineEvents}
        onRefresh={() => {
          lectureRefetch();
          counselRefetch();
          etcRefetch();
          allDayEtcRefetch();
          allDayEtcTimeRefetch();
        }}
        isRefetching={isLoading}
      />
    );
  };

  const viewSettings = useMemo(() => {
    if (isMonth) {
      return {
        calendar: { type: calendarViewType, popover: false },
      };
    }

    if (isListType) {
      return {
        calendar: { type: listCalendarType },
        agenda: { type: 'day' },
      };
    }

    return {
      schedule: {
        type: calendarViewType,
        startDay: isWeek ? calendarSettings.dayRange[0] : undefined,
        endDay: isWeek ? calendarSettings.dayRange[1] : undefined,
        startTime: isMonth ? undefined : filters.time(new Date(calendarSettings.timeRange[0])),
        endTime: isMonth ? undefined : filters.time(new Date(calendarSettings.timeRange[1])),
      },
    };
  }, [isWeek, isMonth, isListType, calendarViewType, calendarSettings, listCalendarType]);

  const allDaysLength = useMemo(() => {
    if (isDay) return [agendaData.length];

    /** 선택된 날짜가 있는 주의 날짜 목록 */
    const startOfWeek = dayjs(currentDate).startOf('isoWeek');
    const weekDays = [startOfWeek];
    for (let i = 1; i < 7; i++) {
      weekDays.push(startOfWeek.add(i, 'day'));
    }

    /** 주의 각 날짜별 개수 */
    const weekCounts = [0, 0, 0, 0, 0, 0, 0];
    agendaData.forEach(({ start, end }) => {
      weekDays.forEach((day, index) => {
        if (day.isBetween(start, end, 'day', '[]')) {
          weekCounts[index]++;
        }
      });
    });
    return weekCounts;
  }, [isDay, agendaData, currentDate]);

  const isDayWeekAllDay = useMemo(() => {
    if (calendarSettings.etcScheduleHide) return false;
    return !!allDayEtcScheduleEvents.length || !!allDayEtcScheduleTimeEvents.length;
  }, [allDayEtcScheduleEvents.length, allDayEtcScheduleTimeEvents.length, calendarSettings.etcScheduleHide]);

  return (
    <>
      {isLoading && <CenterLineLoading />}

      <Container
        ref={calendarSwipeRef}
        {...gestureBind()}
        viewType={calendarViewType}
        isDayWeekAllDay={isDayWeekAllDay}
        allDaysLength={allDaysLength}
        isAtTop={isAtTop}
        holidayTargets={holidayTargets}>
        <Eventcalendar
          theme="ios"
          themeVariant="light"
          view={viewSettings as MbscEventcalendarView}
          data={allCalendarData}
          renderHeader={customCalendarHeader}
          renderScheduleEvent={customRenderScheduleEvent} // 일간, 주간 이벤트 카드
          renderLabel={customRenderCalendarEvent} // 월간 이벤트 카드
          selectedDate={currentDate}
          onEventClick={clickEvent} // 일정 이벤트 클릭
          onCellClick={clickEmptyEvent} // 빈 부분 클릭
          onSelectedDateChange={onMonthPageSlide} // 월간 페이지 변경을 위한 함수. onPageChange와 받는 이벤트가 다름
          onPageChange={onPageChange} // 월간, 주간 이동 시 실제 요청기간을 위한 상태 변경
          onPageLoaded={onPageLoaded}
          firstDay={calendarSettings.dayRange[0] !== 0 && isWeek ? 1 : 0} // 요일 시작을 선택할 수 있는 옵션
          renderAgenda={customRenderListEvent} // 목록형 아젠다 커스텀
          marked={isListType ? marked : undefined}
        />

        {/* Calendar가 렌더되기 전 화면 자체가 깜빡여 보이는 현상 방지 */}
        <CalendarBackground />

        <CreateScheduleDrawer isOpen={isCreateDrawerOpen} onClose={() => setIsCreateDrawerOpen(false)} />
        <CalendarAgendaDrawer
          isOpen={isAgendaDrawerOpen}
          onClose={() => setIsAgendaDrawerOpen(false)}
          allCalendarData={agendaData}
          currentDate={currentDate}
        />
      </Container>
    </>
  );
};

export const Container = styled.div<{
  viewType: CalendarViewType;
  isDayWeekAllDay?: boolean;
  allDaysLength: number[];
  isAtTop?: boolean;
  holidayTargets?: HolidayTargetResponse;
}>(
  /** 기본 스타일(일간) */
  props => calendarStyles.default(props),

  ({ viewType, allDaysLength, isDayWeekAllDay }) => {
    if (viewType === 'month') return calendarStyles.month;
    if (viewType === 'week') return calendarStyles.week({ allDaysLength, isDayWeekAllDay });
    if (viewType === 'list') return calendarStyles.list;
  },
);

const CalendarBackground = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${theme.color.white};
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
`;

export default ScheduleCalendar;
